/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    makeStyles, Typography, useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import {
    bool,
    object, shape, string,
} from 'prop-types';
import { Link } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import parseFontFamilyExtension from '../../../../../../helpers/contentstack/parseFontFamilyExtension';
import derivePassportLoyaltyStatusFromPoints from '../../../../../../helpers/Passport/helper';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles((theme) => ({
    container: (props) => ({
        color: 'black',
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '50px',
        marginTop: props?.isShowingHR ? '8px' : 0,
        [theme.breakpoints.down(600)]: {
            gap: '16px',
            margin: 0,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
    statusText: (props) => ({
        color: props?.color || theme?.palette?.text?.primary,
        fontFamily: parseFontFamilyExtension(props?.fontFamily) || theme?.typography?.fontFamily,
        fontSize: props?.fontSize || '16px',
        textAlign: 'center',
        [theme.breakpoints.up(600)]: {
            lineHeight: 1,
            textAlign: 'left',
        },
    }),
    pointsText: (props) => ({
        color: props?.color || theme?.palette?.text?.primary,
        fontFamily: parseFontFamilyExtension(props?.fontFamily) || theme?.typography?.fontFamily,
        fontSize: props?.fontSize || '16px',
        '& span': {
            fontWeight: 'bold',
        },
        [theme.breakpoints.up(600)]: {
            display: 'inline-block',
        },
    }),
    nonPassport: (props) => ({
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: props?.color || theme?.palette?.text?.primary,
        fontFamily: parseFontFamilyExtension(props?.fontFamily) || theme?.typography?.fontFamily,
        fontSize: props?.fontSize || '16px',
        textAlign: 'left',
        [theme.breakpoints.down(600)]: {
            flexDirection: 'column',
            gap: '16px',
            textAlign: 'center',
        },
    }),
    markDowntext: {
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        margin: 0,
        '& a': {
            color: 'inherit',
        },
        '& p': {
            margin: 0,
        },
        [theme.breakpoints.up(599)]: {
            width: '70%',
        },
    },
    gifterText: {
        display: 'inline-block',
        marginBottom: '17px',
    },
    rightContainer: {
        [theme.breakpoints.up(600)]: {
            width: '45%',
            marginLeft: '6%',
            alignSelf: 'flex-end',
            marginBottom: '-2px',
        },
    },
    linkBtn: (props) => ({
        fontSize: props?.buttonFontSize || '14px',
        fontFamily: parseFontFamilyExtension(props?.buttonFontFamily) || theme?.typography?.fontFamily,
        color: props?.color || '#2f2f2f',
    }),
    joinBtn: (props) => ({
        marginRight: '5%',
        textDecoration: 'none',
        textAlign: 'center',
        borderRadius: '3px',
        fontWeight: 'bold',
        padding: '8px 22px 9px',
        backgroundColor: props?.joinBtn?.backgroundColor || '#2f2f2f',
        color: props?.joinBtn.color || 'white',
        fontSize: props?.buttonFontSize || '14px',
        fontFamily: parseFontFamilyExtension(props?.buttonFontFamily) || theme?.typography?.fontFamily,
        [theme.breakpoints.down(600)]: {
            margin: 0,
        },
    }),
}));

function PassportLoyaltyTierUI({
    blockData, passportStatus, passportPoints, isShowingHR,
}) {
    const dispatch = useDispatch();
    const styles = {
        color: blockData?.font_color?.color,
        fontFamily: blockData?.font_family,
        fontSize: blockData?.font_size,
        buttonFontSize: blockData?.button_font_size,
        buttonFontFamily: blockData?.button_font_family,
        joinBtn: {
            fontFamily: blockData?.join_button?.font_family,
            fontSize: blockData?.join_button?.font_size,
            backgroundColor: blockData?.join_button?.background_color?.color,
            color: blockData?.join_button?.font_color?.color,
        },
        isShowingHR,
    };
    const classes = useStyles(styles);
    const loyaltyStatus = derivePassportLoyaltyStatusFromPoints(passportPoints);
    const isMatch = useMediaQuery('(min-width:600px)');

    const handleClick = (linkUrl) => {
        dispatch(trackEvent({
            eventCategory: 'Personalized widget',
            eventAction: 'Click',
            eventLabel: linkUrl,
        }));
    };

    return (
        <div className={classes.container}>
            {
                passportStatus === 'A' ? (
                    <>
                        <div className={classes.statusText}>
                            {isMatch && <strong className={classes.gifterText}>YOUR CURRENT GIFTER STATUS</strong>}
                            <Typography component="p" className={classes.statusText} data-testid="loyalty-message">
                                You are <strong>{loyaltyStatus?.status}</strong>!{' '}
                                {loyaltyStatus?.pointRequired
                                    && (
                                        <div className={classes.pointsText}>
                                            Earn <span>{loyaltyStatus?.pointRequired}</span> to unlock{' '}
                                            <span>{loyaltyStatus?.nextStatus}</span> Status.
                                        </div>
                                    )}
                            </Typography>
                        </div>
                        <div className={classes.rightContainer}>
                            <Link
                                to={blockData?.passport_user?.href || '/passport'}
                                onClick={() => handleClick(blockData?.passport_user?.title || 'Learn about our Celebrations Passport® program and benefits')}
                                className={classes.linkBtn}
                            >
                                {blockData?.passport_user?.title || 'Learn about our Celebrations Passport® program and benefits'}
                            </Link>
                        </div>
                    </>
                )
                    : (
                        <div className={classes.nonPassport}>
                            <ReactMarkdown
                                data-testid="non-passport-status"
                                className={classes.markDowntext}
                                source={blockData?.non_passport_user || 'Not a Celebration Passport member yet?  \n  Join today and enjoy [exclusive perks](/exclusive).'}
                                escapeHtml={false}
                            />
                            <Link
                                to={blockData?.join_button?.link?.href || '#'}
                                onClick={() => handleClick(blockData?.join_button?.link?.title || 'JOIN TODAY')}
                                className={classes.joinBtn}
                            >
                                {blockData?.join_button?.link?.title || 'JOIN TODAY ⟩'}
                            </Link>
                        </div>
                    )
            }
        </div>
    );
}

PassportLoyaltyTierUI.propTypes = {
    blockData: shape({
        font_size: string.isRequired,
        font_color: object.isRequired,
        font_family: string.isRequired,
        button_font_size: string.isRequired,
        button_font_family: string.isRequired,
    }),
    passportStatus: string,
    passportPoints: string,
    isShowingHR: bool,
};

PassportLoyaltyTierUI.defaultProps = {
    blockData: {},
    passportStatus: '',
    passportPoints: undefined,
    isShowingHR: false,
};
export default PassportLoyaltyTierUI;
