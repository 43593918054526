/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { func, object } from 'prop-types';
import {
    Button, Collapse, Grid, makeStyles, useMediaQuery,
} from '@material-ui/core';

import { getIsAuthenticatedStatus, getUserType } from '../../../../../../state/ducks/App/ducks/Common/Common-Selectors';
import RegisterUserGreetingUI from './RegisterUserGreetingUI';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import PassportLoyaltyTierUI from './Partials/PassportLoyaltyTierUI';
import UserGiftListContainer from './Partials/UserGiftListContainer';
import { getLoginModuleVisibility } from '../../../../../../state/ducks/App/App-Selectors';
import { setLoginModuleBannerVisibility } from '../../../../../../state/ducks/App/App-Actions';
import { getEnterpriseIdSegments } from '../../../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import RegisterUserNewUI from './Partials/RegisterUserNewUI';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import noop from '../../../../../helpers/noop';

const useStyles = makeStyles((theme) => ({
    container: (props) => ({
        padding: '16px',
        textAlign: 'center',
        backgroundColor: props?.backgroundColor,
        margin: props?.margin,
        maxWidth: props?.maxWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
        [theme.breakpoints.down(600)]: {
            padding: '16px 12px',
        },
    }),
    btnContainer: {
        position: 'absolute',
        right: '15px',
        top: '15px',
        padding: 0,
        minWidth: 0,
    },
    closeImg: {
        maxWidth: '20px',
    },
    leftContainer: {
        [theme.breakpoints.up(600)]: {
            borderBottom: 'none',
            paddingBottom: '0px',
            borderRight: '1px solid #2F2F2F4D',
        },
        '&.showHR': {
            [theme.breakpoints.down(600)]: {
                borderBottom: '1px solid #2F2F2F4D',
                paddingBottom: '16px',
            },
        },
    },
    rightContainer: {
        paddingTop: '16px',
        flexWrap: 'nowrap',
        gap: '10px',
        [theme.breakpoints.up(600)]: {
            paddingTop: '0',
            paddingLeft: '20px',
            gap: '11px',
        },
    },
    hrLine: {
        borderTop: '1px solid #2F2F2F4D',
        margin: '10px 0px 0px 0px',
        [theme.breakpoints.down(600)]: {
            margin: '6px 0px',
        },
    },
}));

function RegisterUserGreetingContainer({ cmsData, salesforceResponse, setShowSkeleton }) {
    const cmsStyles = {
        backgroundColor: cmsData?.background_color?.color || 'white',
        margin: cmsData?.margin || '0px',
        maxWidth: cmsData?.max_width || '100%',
    };

    const {
        registered_user = [], button = {}, main = {}, is_old_design,
    } = cmsData || {};

    const registerUserData = registered_user.reduce((acc, ele) => {
        const [key, value] = Object.entries(ele)[0];
        if (key) {
            return { ...acc, [key]: value };
        }
        return acc;
    }, {});

    const classes = useStyles(cmsStyles);
    const dispatch = useDispatch();

    // Hide passport block on mobile when giftlist block is present
    const [hidePassportBlock, setHidePassportBlock] = useState(false);
    const isDesktopView = useMediaQuery('(min-width: 600px)');
    const showBanner = useSelector(getLoginModuleVisibility);
    const userType = useSelector(getUserType);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const userSegments = useSelector(getEnterpriseIdSegments);

    const isModuleEnabledFromCMS = cmsData?.is_module_enabled;
    const isUserEligible = isAuthenticated && userType === 'R';

    useEffect(() => {
        setShowSkeleton(false);

        if (isUserEligible) {
            if (isModuleEnabledFromCMS) {
                dispatch(trackEvent({
                    eventCategory: 'Personalized widget',
                    eventAction: 'Impression',
                    eventLabel: 'Variant',
                    nonInteraction: true,
                }));
            } else {
                dispatch(trackEvent({
                    eventCategory: 'Personalized widget',
                    eventAction: 'Impression',
                    eventLabel: 'Control',
                    nonInteraction: true,
                }));
            }
        }
    }, [isUserEligible, isModuleEnabledFromCMS]);

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse,
        isFireImpression: true,
        page: { type: 'home' },
    });

    const renderCloseBtn = () => {
        const closeIcon = cmsData?.close_icon;
        return (
            <Button className={classes.btnContainer} disableRipple onClick={() => dispatch(setLoginModuleBannerVisibility(false))}>
                {
                    closeIcon?.url
                        ? <img src={closeIcon?.url} className={classes.closeImg} alt="close" />
                        : <CloseIcon />
                }
            </Button>
        );
    };

    if (!is_old_design) {
        return (
            <RegisterUserNewUI
                cmsData={cmsData}
                salesforceResponse={salesforceResponse}
                trackClickThrough={trackClickThrough}
            />
        );
    }

    if (isUserEligible && isModuleEnabledFromCMS) {
        return (
            <Collapse in={isUserEligible && showBanner} timeout={1500}>
                <Grid container className={classes.container}>
                    {renderCloseBtn()}
                    <Grid
                        item
                        container
                        xs={12}
                        sm={4}
                        className={`${classes.leftContainer} ${hidePassportBlock ? '' : 'showHR'}`}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <RegisterUserGreetingUI
                            cmsData={{ button, main }}
                            upComingOrder={userSegments?.upcoming_order === 'T'}
                            registerUserBlock={registerUserData?.main}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        xs={12}
                        sm={8}
                        className={classes.rightContainer}
                    >
                        {(isDesktopView || !hidePassportBlock) && (
                            <PassportLoyaltyTierUI
                                blockData={registerUserData?.loyalty_tier}
                                passportStatus={userSegments?.passport_status}
                                passportPoints={userSegments?.passport_points}
                                isShowingHR={hidePassportBlock}
                            />
                        )}
                        {hidePassportBlock && <hr className={classes.hrLine} data-testid="hr-line" />}
                        <UserGiftListContainer
                            setHidePassportBlock={setHidePassportBlock}
                            blockData={registerUserData?.giftlist}
                            giftListActive={userSegments?.activegiftlist === 'T'}
                        />
                    </Grid>
                </Grid>
            </Collapse>
        );
    }
    return null;
}

RegisterUserGreetingContainer.propTypes = {
    cmsData: object.isRequired,
    salesforceResponse: object.isRequired,
    setShowSkeleton: func,
};

RegisterUserGreetingContainer.defaultProps = {
    setShowSkeleton: noop,
};

export default RegisterUserGreetingContainer;
