/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    makeStyles, Typography, useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import {
    shape, string, bool, func,
} from 'prop-types';
import { useSelector } from 'react-redux';

import parseFontFamilyExtension from '../../../../../../helpers/contentstack/parseFontFamilyExtension';
import { getProfileInfo } from '../../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import noop from '../../../../../../helpers/noop';

const useStyles = makeStyles((theme) => ({
    container: (props) => ({
        padding: '17px 0px',
        fontFamily: parseFontFamilyExtension(props?.main?.font_family) || 'Lato,sans-serif',
        color: props?.main?.font_color?.color || '#2f2f2f',
        backgroundColor: props?.background_color?.color || 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0px 24px',
        margin: '0px 15px',
        [theme.breakpoints.down(599)]: {
            gap: '0px 42px',
            padding: '15px',
            margin: props?.margin || '0px',
        },
    }),
    copyText: (props) => ({
        fontFamily: 'inherit',
        fontSize: props?.main?.font_size || '16px',
        letterSpacing: '0.01em',
        [theme.breakpoints.up(599)]: {
            fontSize: props?.main?.font_size || '24px',
        },
    }),
    nameText: {
        fontWeight: 'bold',
    },
    button: (props) => ({
        fontFamily: parseFontFamilyExtension(props?.button?.font_family) || 'Lato,sans-serif',
        padding: '8px 20px',
        fontWeight: 'bold',
        backgroundColor: 'white',
        fontSize: props?.button?.font_size || '16px',
        border: '1px solid black',
        borderRadius: '3px',
        letterSpacing: '0.04em',
        cursor: 'pointer',
        minWidth: '100px',
        [theme.breakpoints.up(599)]: {
            borderWidth: '2px',
        },
    }),
}));

function RegisterUserNewUI({ cmsData, trackClickThrough }) {
    const { main = {}, button = {}, is_old_design } = cmsData || {};
    const main_copy = main?.copy || 'Hello {user}, Welcome';

    const classes = useStyles(cmsData);
    const user = useSelector(getProfileInfo);
    const isDesktopView = useMediaQuery('(min-width: 600px)');

    const handleClick = () => {
        trackClickThrough();
    };

    const renderUserName = () => {
        if (main_copy?.includes('{user}')) {
            return user?.firstName;
        }
        return '';
    };

    const linesArr = main_copy?.split('{newline}') || [];
    let userArr = [];
    if (linesArr?.length > 0) {
        userArr = linesArr[0]?.split('{user}');
    }

    return (
        <div className={classes.container}>
            <div className={classes.textContainer}>
                {
                    isDesktopView ? (
                        <Typography component="p" className={classes.copyText}>
                            <span className={is_old_design ? classes.nameText : ''}>
                                {userArr[0] || ''}
                                {renderUserName()}
                                {userArr[1] || ''}
                            </span>
                            {linesArr[1] || ''}
                        </Typography>
                    )
                        : (
                            <>
                                <Typography component="p" className={`${classes.copyText} ${classes.nameText}`}>
                                    {userArr[0] || ''}
                                    {renderUserName()}
                                    {userArr[1] || ''}
                                </Typography>
                                {linesArr[1] && (
                                    <Typography component="p" className={classes.copyText}>
                                        {linesArr[1]}
                                    </Typography>
                                )}
                            </>
                        )
                }
            </div>

            {
                button?.copy && (
                    <button
                        type="button"
                        className={classes.button}
                        onClick={handleClick}
                    >
                        {button?.copy}
                    </button>
                )
            }

        </div>
    );
}

RegisterUserNewUI.propTypes = {
    cmsData: shape({
        font_family: string,
        font_size: string,
        is_old_design: bool,
        margin: string,
        main_copy: string,
        button_copy: string,
        button_font_family: string,
        button_font_size: string,
        font_color: shape({
            color: string,
        }),
        background_color: shape({
            color: string,
        }),
    }),
    trackClickThrough: func,
};

RegisterUserNewUI.defaultProps = {
    cmsData: {},
    trackClickThrough: noop,
};
export default RegisterUserNewUI;
