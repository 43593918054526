/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    bool,
    func, shape, string,
} from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

import EventDispatcher from '../../../../../../pages/Account/state/reducers/EventDispatcher';
import { getGiftListOccasion } from '../../../../../../pages/Account/state/reducers/Selectors';
import IconLabelUI from './Common/IconLabelUI';

function UserGiftListContainer({ setHidePassportBlock, blockData, giftListActive }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const styles = {
        color: blockData?.font_color?.color,
        fontFamily: blockData?.font_family,
        fontSize: blockData?.font_size,
        buttonFontSize: blockData?.button_font_size,
        buttonFontFamily: blockData?.button_font_family,
    };

    const giftListOccasion = useSelector(getGiftListOccasion);
    useEffect(() => {
        dispatch(EventDispatcher.fetchGiftListOccasions());
    }, []);

    useEffect(() => {
        if (giftListActive) setHidePassportBlock(true);
    }, [giftListActive]);

    const handleClick = () => {
        dispatch(trackEvent({
            eventCategory: 'Personalized widget',
            eventAction: 'Click',
            eventLabel: 'View your gift list',
        }));
        history.push('/account/gift-list');
    };

    if (giftListActive) {
        const name = giftListOccasion[0]?.name;
        let message = '';

        switch (name) {
            case 'HOLIDAY GIFTING':
                message = 'Your Holiday giftlist is ready.';
                break;

            default:
                message = 'Your giftlist is ready.';
        }

        return (
            <IconLabelUI
                message={message}
                linkText="View your giftlist"
                iconUrl={blockData.icon?.url}
                iconsAltText="giftlist"
                handleClick={handleClick}
                styles={styles}
                arrowIcon={blockData?.arrow_icon || ''}
            />
        );
    }
    return null;
}

UserGiftListContainer.propTypes = {
    setHidePassportBlock: func.isRequired,
    blockData: shape({
        icon: shape({
            url: string,
        }),
    }),
    giftListActive: bool,
};

UserGiftListContainer.defaultProps = {
    blockData: {
        icon: {
            url: '',
        },
    },
    giftListActive: false,
};
export default UserGiftListContainer;
