/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { bool, func, object } from 'prop-types';
import { useSelector } from 'react-redux';
import mbpLogger from 'mbp-logger';
import cookies from '../../../../helpers/common/storage/cookies';

import { getBrandName } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getIsAuthenticatedStatus } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import useSalesforcePersonalizedContentQuery from '../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import ComponentErrorBoundary from '../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import noop from '../../../../helpers/noop';
import GuestUserGreetingContainer from './GuestUserGreeting/GuestUserGreetingContainer';
import RegisterUserGreetingContainer from './RegisterUserGreeting/RegisterUserGreetingContainer';

function PersonalizedUserGreetingContainer({
    blockData, isFromPersonalizedContentBlock, salesforceResponse, setShowSkeleton,
}) {
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);

    let cmsData = null;

    if (isFromPersonalizedContentBlock) {
        cmsData = blockData;
    } else {
        cmsData = blockData?.reference?.length > 0 ? blockData?.reference[0] : null;
    }

    const userType = cmsData?.user_type || '';

    switch (userType) {
        case 'guest':
            if (!isAuthenticated) {
                if (isFromPersonalizedContentBlock) {
                    return <GuestUserGreetingContainer cmsData={cmsData} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />;
                }

                // for collection page without personalized content block.
                return <PersonalizedUserGreetingContainerQuery cmsData={cmsData} setShowSkeleton={setShowSkeleton} />;
            }
            return null;

        case 'registered':
            return <RegisterUserGreetingContainer cmsData={cmsData} salesforceResponse={salesforceResponse} setShowSkeleton={setShowSkeleton} />;

        default:
            return null;
    }
}

PersonalizedUserGreetingContainer.propTypes = {
    blockData: object.isRequired,
    isFromPersonalizedContentBlock: bool,
    salesforceResponse: object,
    setShowSkeleton: func,
};

PersonalizedUserGreetingContainer.defaultProps = {
    isFromPersonalizedContentBlock: false,
    salesforceResponse: null,
    setShowSkeleton: noop,
};

export const PersonalizedUserGreetingContainerQuery = ({ setShowSkeleton }) => {
    const content_type = 'personalized_login_modules';
    const brand = useSelector(getBrandName) || '1800flowers';
    const interactionName = `${brand} - Get Campaign - Login module`;
    const persistedUserProfile = cookies.get('persisted_user_profile');

    const {
        loading, error, data, variables,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'PersonalizedUserGreeting',
        interactionName,
        targeting: {
            pageType: 'home',
        },
        isSessionHasAccount: !!persistedUserProfile,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    });

    if (loading || variables?.skip) {
        return null;
    }

    if (error) {
        mbpLogger.logError({
            function: `PersonalizedUserGreetingContainerQuery - content_type = ${content_type} interactionName = ${interactionName}`,
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    if (data && data?.contentSF?.content) {
        const sfCampaignResponse = data?.contentSF;
        const sfCMSData = sfCampaignResponse?.content;
        return (
            <ComponentErrorBoundary function="LoadableGuestUserGreetingContainer" module="personalized_login_modules">
                <GuestUserGreetingContainer cmsData={sfCMSData} salesforceResponse={sfCampaignResponse} setShowSkeleton={setShowSkeleton} />
            </ComponentErrorBoundary>
        );
    }
    return null;
};

PersonalizedUserGreetingContainerQuery.propTypes = {
    setShowSkeleton: func,
};

PersonalizedUserGreetingContainerQuery.defaultProps = {
    setShowSkeleton: noop,
};

export default PersonalizedUserGreetingContainer;
