/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { makeStyles, Typography } from '@material-ui/core';
import { func, object, string } from 'prop-types';
import React from 'react';
import parseFontFamilyExtension from '../../../../../../../helpers/contentstack/parseFontFamilyExtension';
import { decode } from '../../../../../../ContentTypeComponents/utils/htmlEntity';

const useStyles = makeStyles((theme) => ({
    root: () => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginLeft: '50px',
        [theme.breakpoints.down(600)]: {
            margin: '0 20px',
        },
    }),
    icon: {
        width: '34px',
        height: '34px',
        marginRight: '16px',
        fontSize: '10px',
        [theme.breakpoints.down(600)]: {
            width: '42px',
            height: '42px',
        },
    },
    msgContainer: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    message: (props) => ({
        textAlign: 'left',
        color: props?.color || theme?.palette?.text?.primary,
        fontFamily: parseFontFamilyExtension(props?.fontFamily) || theme?.typography?.fontFamily,
        fontSize: props?.fontSize || '16px',
        marginRight: '5px',
        [theme.breakpoints.up(599)]: {
            marginRight: '20px',
        },
    }),
    btnLink: (props) => ({
        cursor: 'pointer',
        textDecoration: 'underline',
        fontFamily: parseFontFamilyExtension(props?.buttonFontFamily) || theme?.typography?.fontFamily,
        fontSize: props?.buttonFontSize || '14px',
    }),
    link: {
        display: 'inline',
        marginLeft: '3px',
        fontSize: '13px',
        fontWeight: 800,
    },
}));

function IconLabelUI({
    iconsAltText,
    iconUrl,
    message,
    handleClick,
    linkText,
    arrowIcon,
    styles,
}) {
    const classes = useStyles(styles);
    const decodedArrowIcon = decode(arrowIcon);

    return (
        <div className={classes.root}>
            {iconUrl && <img src={iconUrl} className={classes.icon} alt={iconsAltText} />}
            <div className={classes.msgContainer}>
                <Typography
                    component="p"
                    className={classes.message}
                >
                    {message}
                </Typography>
                <div
                    role="button"
                    onClick={handleClick}
                    tabIndex="0"
                    onKeyDown={handleClick}
                    className={classes.btnLink}
                >
                    {linkText}
                </div>
                <div className={classes.link}>{decodedArrowIcon}</div>
            </div>
        </div>
    );
}

IconLabelUI.propTypes = {
    iconsAltText: string.isRequired,
    message: string.isRequired,
    iconUrl: string.isRequired,
    handleClick: func.isRequired,
    linkText: string.isRequired,
    styles: object.isRequired,
    arrowIcon: string,
};

IconLabelUI.defaultProps = {
    arrowIcon: '',
};

export default IconLabelUI;
