/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable import/prefer-default-export */

export const encode = (str) => {
    const buf = [];
    // eslint-disable-next-line no-plusplus
    for (let i = str.length - 1; i >= 0; i--) {
        buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
    }
    return buf.join('');
};

export const decode = (str) => (str.replace(/&#(\d+);/g, (match, dec) => (String.fromCharCode(dec))));
