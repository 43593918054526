/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

function derivePassportLoyaltyStatusFromPoints(passportPoints) {
    let status = 'Gifty';
    let nextStatus = 'Gifted';
    let pointRequired = 500;
    const points = parseInt(passportPoints, 10);

    if (typeof points === 'number' && points >= 0) {
        if (points < 500) {
            status = 'Gifty';
            nextStatus = 'Gifted';
            pointRequired = 500 - points;
        } else if (points < 1200) {
            status = 'Gifted';
            nextStatus = 'Giftastic';
            pointRequired = 1200 - points;
        } else {
            status = 'Giftastic';
            nextStatus = '';
            pointRequired = '';
        }
    }
    return {
        status,
        nextStatus,
        pointRequired,
    };
}

export default derivePassportLoyaltyStatusFromPoints;
