/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    makeStyles, Typography,
} from '@material-ui/core';
import React from 'react';

import {
    bool, object, shape, string,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import parseFontFamilyExtension from '../../../../../helpers/contentstack/parseFontFamilyExtension';
import { getProfileInfo } from '../../../../../../state/ducks/App/ducks/Common/Common-Selectors';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles((theme) => ({
    container: (props) => ({
        fontFamily: parseFontFamilyExtension(props?.main?.font_family) || theme?.typography?.fontFamily,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        gap: '15%',
        [theme.breakpoints.down(599)]: {
            gap: '16px',
        },
    }),
    copyText: (props) => ({
        color: props?.main?.font_color?.color || theme?.palette?.text?.primary,
        fontFamily: parseFontFamilyExtension(props?.main?.font_family) || theme?.typography?.fontFamily,
        fontSize: props?.main?.font_size || '16px',
        letterSpacing: '0.01em',
        [theme.breakpoints.up(599)]: {
            fontSize: props?.main?.font_size || '24px',
        },
    }),
    nameText: {
        fontWeight: 'bold',
    },
    linkContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
    },
    icon: {
        width: '56px',
        height: '56px',
        fontSize: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: '100%',
            height: '100%',
        },
        [theme.breakpoints.down(600)]: {
            width: '42px',
            height: '42px',
        },
    },
    circularIcon: {
        border: '0.5px solid #D5D5D5',
        overflow: 'hidden',
        padding: '3px',
        borderRadius: '50%',
        '& img': {
            borderRadius: '50%',
        },
    },
    linkButton: (props) => ({
        fontSize: props?.button?.font_size || '14px',
        fontFamily: parseFontFamilyExtension(props?.button?.font_family) || theme?.typography?.fontFamily,
    }),
}));

function RegisterUserGreetingUI({ cmsData, registerUserBlock, upComingOrder }) {
    const classes = useStyles(cmsData);
    const dispatch = useDispatch();
    const { firstName } = useSelector(getProfileInfo);
    const main_copy = cmsData?.main?.copy || 'Welcome back {user}!';
    const linkTitle = upComingOrder ? registerUserBlock?.link?.title : registerUserBlock?.default?.title;
    const linkUrl = upComingOrder ? registerUserBlock?.link?.href : registerUserBlock?.default?.href;
    const iconUrl = upComingOrder ? registerUserBlock.track_order_icon?.url : registerUserBlock?.default_icon?.url;

    const renderUserName = () => {
        if (firstName?.length > 0 && main_copy.includes('{user}')) {
            return (
                <span className={classes.nameText}>
                    {`${firstName[0]?.toUpperCase()}${firstName.slice(1)}`}
                </span>
            );
        }
        return '';
    };

    const handleClick = () => {
        dispatch(trackEvent({
            eventCategory: 'Personalized widget',
            eventAction: 'Click',
            eventLabel: linkTitle || 'Weekly Deals For You',
        }));
    };

    const linesArr = main_copy?.split('{newline}') || [];
    let userArr = [];
    if (linesArr?.length > 0) {
        userArr = linesArr[0]?.split('{user}');
    }

    return (
        <div className={classes.container}>
            <div>
                <Typography component="p" className={classes.copyText} data-testid="welcome-message">
                    {userArr[0] || ''}
                    {renderUserName()}
                    {userArr[1] || ''}
                    {linesArr[1] || ''}
                </Typography>
            </div>
            <div className={classes.linkContainer}>
                <div className={`${classes.icon} ${upComingOrder ? '' : classes.circularIcon}`}>
                    {iconUrl && <img src={iconUrl} alt="track order" />}
                </div>
                <Link
                    to={linkUrl || '#'}
                    className={classes.linkButton}
                    style={{ color: cmsData?.main?.font_color?.color || '#2f2f2f' }}
                    onClick={handleClick}
                >
                    {linkTitle || 'Weekly Deals For You'}
                </Link>
            </div>
        </div>
    );
}

RegisterUserGreetingUI.propTypes = {
    cmsData: shape({
        font_family: string,
        font_size: string,
        margin: string,
        font_color: shape({
            color: string,
        }),
        background_color: shape({
            color: string,
        }),
    }),
    registerUserBlock: shape({
        track_order_icon: object,
    }),
    upComingOrder: bool,
};

RegisterUserGreetingUI.defaultProps = {
    cmsData: {},
    registerUserBlock: {},
    upComingOrder: false,
};
export default RegisterUserGreetingUI;
